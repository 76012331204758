<template>
  <content-wrapper>
    <object-summary />
    <near-objects title="Рядом находятся" :current-id="this.$route.params.id" />
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/Wrappers/ContentWrapper";
import ObjectSummary from "@/components/ObjectSummary";
import NearObjects from "@/components/NearObjects";

export default {
  components: {
    ContentWrapper,
    ObjectSummary,
    NearObjects
  },
  watch: {
    $route: "fetchData"
  },
  mounted() {
    // this.fetchData();
  },
  methods: {
    fetchData() {
      api.getObject(this.$route.params.id).then(res => {
        this.$store.dispatch("setSelectedObject", res.data);
        this.$store.dispatch("fetchNearObjects", res.data.id);
      });
    }
  }
};
</script>


